<div class="map-holder">
  <div
    leaflet
    class="map"
    *ngIf="mapReady"
    [leafletLayers]="mapLayers"
    [leafletOptions]="leafletOptions"
    [leafletFitBounds]="mapFitToBounds"
    (leafletMapReady)="onMapReady($event)"
    [leafletFitBoundsOptions]="fitBoundsOptions"
  ></div>
  <button type="button" class="btn-current-location" (click)="getCurrentLocation()">
    <mat-icon class="btn-current-location_icon" svgIcon="current-location"></mat-icon>
  </button>
</div>

<span class="instructions">
  {{ 'post.location.you_can_search_or_click' | translate }}
</span>
<div *ngIf="location">
  <div class="form-row">
    <mat-label
      >{{ 'post.location.lat' | translate }}
      <span class="color-accent" *ngIf="required">*</span></mat-label
    >
    <mat-form-field
      appearance="outline"
      [ngClass]="{ 'location-error': emptyFieldLat || noSpecialCharactersLat }"
    >
      <input
        matInput
        disabled
        [data-qa]="'location-lat'"
        [(ngModel)]="location.lat"
        (ngModelChange)="checkErrors()"
        (focusout)="onFocusOut()"
      />
      <mzima-client-button
        matSuffix
        fill="clear"
        color="secondary"
        [iconOnly]="true"
        (buttonClick)="clearLocationField()"
        *ngIf="location.lat"
      >
        <mat-icon icon svgIcon="close"></mat-icon>
      </mzima-client-button>
    </mat-form-field>
    <p *ngIf="emptyFieldLat" class="location-error">
      {{ 'post.location.errors.empty_lat' | translate }}
    </p>
    <p *ngIf="noSpecialCharactersLat" class="location-error">
      {{ 'post.location.errors.special_lat' | translate }}
    </p>
  </div>

  <div class="form-row">
    <mat-label
      >{{ 'post.location.lng' | translate
      }}<span class="color-accent" *ngIf="required">*</span></mat-label
    >
    <mat-form-field
      appearance="outline"
      [ngClass]="{ 'location-error': emptyFieldLng || noSpecialCharactersLng }"
    >
      <input
        matInput
        disabled
        [data-qa]="'location-lng'"
        [(ngModel)]="location.lng"
        (ngModelChange)="checkErrors()"
        (focusout)="onFocusOut()"
      />
      <mzima-client-button
        matSuffix
        fill="clear"
        color="secondary"
        [iconOnly]="true"
        (buttonClick)="clearLocationField()"
        *ngIf="location.lng"
      >
        <mat-icon icon svgIcon="close"></mat-icon>
      </mzima-client-button>
    </mat-form-field>
    <p *ngIf="emptyFieldLng" class="location-error">
      {{ 'post.location.errors.empty_lng' | translate }}
    </p>
    <p *ngIf="noSpecialCharactersLng" class="location-error">
      {{ 'post.location.errors.special_lng' | translate }}
    </p>
  </div>
</div>
