<mzima-client-button
  tabindex="-1"
  fill="outline"
  [iconOnly]="true"
  color="light-gray"
  [data-qa]="'btn-close'"
  class="modal__close-btn"
  (buttonClick)="closeModal()"
  ariaLabel="{{ 'modal.button.close' | translate }}"
>
  <mat-icon icon svgIcon="close"></mat-icon>
</mzima-client-button>

<ng-container *ngIf="currentView === CollectionView.List; else createForm">
  <div class="head">
    <strong mat-dialog-title>
      {{ (post ? 'set.add_to_collection' : 'app.collection') | translate }}
      <mzima-client-button
        fill="clear"
        color="secondary"
        [iconOnly]="true"
        class="tooltip-btn"
        tabindex="-1"
        matTooltipPosition="after"
        [matTooltip]="'set.collection_definition' | translate"
      >
        <mat-icon icon svgIcon="tooltip"></mat-icon>
      </mzima-client-button>
    </strong>
    <mzima-client-button
      *ngIf="isLoggedIn && !post && isDesktop"
      (buttonClick)="addNewCollection()"
      tabindex="-1"
      [data-qa]="'add-collection'"
    >
      <span>{{ 'app.add_collection' | translate }}</span>
      <mat-icon icon svgIcon="plus"></mat-icon>
    </mzima-client-button>
  </div>

  <form
    class="search-form"
    [formGroup]="searchForm"
    (ngSubmit)="loadData(searchForm.get('query')?.value)"
  >
    <div class="form-row">
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="{{ 'location.search' | translate }}"
          name="query"
          formControlName="query"
        />
        <mzima-client-button
          matPrefix
          fill="clear"
          color="secondary"
          [iconOnly]="true"
          type="submit"
        >
          <mat-icon icon svgIcon="search-small"></mat-icon>
        </mzima-client-button>
      </mat-form-field>
    </div>
    <mzima-client-button
      *ngIf="isLoggedIn && !post && !isDesktop"
      (buttonClick)="addNewCollection()"
      tabindex="-1"
    >
      <span>{{ 'app.add' | translate }}</span>
      <mat-icon icon svgIcon="plus"></mat-icon>
    </mzima-client-button>
  </form>

  <ng-container *ngIf="!isLoading; else loader">
    <div mat-dialog-content>
      <div class="collection-list" *ngIf="!post">
        <a
          *ngFor="let item of collectionList"
          [routerLink]="['/collections', item.id]"
          class="collection-list__item"
          (click)="goToCollection(item)"
          (keydown.space)="goToCollection(item)"
          [data-qa]="'collection-item'"
        >
          <app-collection-item
            [collection]="item"
            [actions]="item.my_collection || isManageCollections"
            (edit)="editCollection(item, $event)"
            (delete)="deleteCollection(item, $event)"
          >
          </app-collection-item>
        </a>
      </div>
      <div class="collection-list" *ngIf="post">
        <app-collection-item
          *ngFor="let item of collectionList"
          [collection]="item"
          [actions]="item.my_collection || isManageCollections"
          [selectable]="post && !!post.id"
          class="collection-list__item"
          [data-qa]="'collection-item'"
          [checked]="isPostInCollection(item)"
          (checkedChange)="onCheckChange($event, item)"
        >
        </app-collection-item>
      </div>
    </div>
  </ng-container>
  <mat-dialog-actions align="end">
    <mzima-client-button (buttonClick)="closeModal()">
      {{ 'modal.button.done' | translate }}
    </mzima-client-button>
  </mat-dialog-actions>
</ng-container>

<ng-template #createForm>
  <div class="head">
    <strong mat-dialog-title>
      {{
        (currentView === CollectionView.Create ? 'app.add_collection' : 'app.edit_collection')
          | translate
      }}
    </strong>
  </div>

  <div mat-dialog-content class="create-form">
    <form [formGroup]="collectionForm" novalidate>
      <div class="form-row">
        <mat-label>
          {{ 'set.collection_name' | translate }} <span class="color-accent">*</span>
        </mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="{{ 'set.name_your_collection' | translate }}"
            formControlName="name"
            required
            [data-qa]="'name'"
          />
          <mat-error *ngIf="collectionForm.get('name')?.hasError('required')">
            {{ 'collection.validation.required.name' | translate }}
          </mat-error>
        </mat-form-field>
        <ng-container *ngFor="let err of formErrors">
          <ng-container *ngIf="err?.field === 'name'">
            <mat-error *ngFor="let msg of err?.error_messages">
              {{ msg | translate }}
            </mat-error>
          </ng-container>
        </ng-container>
      </div>
      <div class="form-row">
        <mat-label>{{ 'set.description' | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <textarea
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="6"
            formControlName="description"
            [data-qa]="'description'"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="form-row" *ngIf="featuredEnabled">
        <div class="toggle">
          <mat-slide-toggle formControlName="featured" name="first-name">
            {{ 'set.featured' | translate }}
          </mat-slide-toggle>
        </div>
      </div>
      <div class="form-row">
        <mat-label>{{ 'app.who_can_see_this' | translate }}</mat-label>
        <p *ngIf="!isManageCollections">{{ 'collection.visible_to_you' | translate }}</p>
        <app-group-checkbox-select
          *ngIf="isManageCollections"
          [data]="roleOptions"
          formControlName="visible_to"
          [data-qa]="'visible_to_you'"
        >
        </app-group-checkbox-select>
      </div>
      <div class="form-row">
        <mat-label> {{ 'set.default_viewing_mode' | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="view" disableOptionCentering>
            <ng-container *ngFor="let view of views">
              <mat-option [value]="view.name">
                {{ view.display_name | translate }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-row">
        <div class="toggle">
          <mat-slide-toggle formControlName="is_notifications_enabled" name="first-name">
            {{ 'nav.receive_notifications' | translate }}
          </mat-slide-toggle>
        </div>
      </div>
    </form>
  </div>

  <mat-dialog-actions align="end">
    <mzima-client-button fill="outline" color="secondary" (buttonClick)="closeModal()">
      {{ 'app.cancel' | translate }}
    </mzima-client-button>
    <mzima-client-button
      (buttonClick)="saveCollection()"
      [disabled]="collectionForm.invalid"
      id="save-collection-btn"
      [attr.data-qa]="'save-collection-btn'"
    >
      {{ 'app.save' | translate }}
    </mzima-client-button>
  </mat-dialog-actions>
</ng-template>

<ng-template #loader>
  <app-spinner class="spinner"></app-spinner>
</ng-template>
