<div class="form-row">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-form-field appearance="outline">
    <span matPrefix class="selected-color" [ngStyle]="{ backgroundColor: value || '#eee' }"></span>
    <input
      matInput
      readonly
      [(ngModel)]="value"
      [matMenuTriggerFor]="menu"
      [placeholder]="placeholder"
    />
  </mat-form-field>
</div>

<mat-menu #menu="matMenu" class="colorpicker-menu">
  <input
    matInput
    [value]="value"
    cpPresetLabel=""
    cpPosition="auto"
    [cpToggle]="true"
    cpOutputFormat="hex"
    [(colorPicker)]="value"
    cpDialogDisplay="inline"
    cpAlphaChannel="disabled"
    [cpPresetColors]="preset"
    [style.background]="value"
    (colorPickerChange)="colorChange()"
  />
  <div class="colorpicker-menu__buttons">
    <mzima-client-button>{{ okButton }}</mzima-client-button>
  </div>
</mat-menu>
